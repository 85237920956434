const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const maxHourTime = 3600;
const maxYearTime = 31536000;

const relativeTimePeriods: [number, string][] = [
  [maxYearTime, 'year'],
  [2419200, 'month'],
  [604800, 'week'],
  [86400, 'day'],
  [maxHourTime, 'h'],
  [60, 'm'],
  [1, 's'],
];

const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function relativeTime(d: string) {
  let date = new Date(d);
  if (!(date instanceof Date)) {
      date = new Date(date * 1000);
  }
  const seconds = (new Date().getTime() - date.getTime()) / 1000;
  for (const [secondsPer, name] of relativeTimePeriods) {
      if (seconds > maxHourTime && seconds < maxYearTime) {
          return `${date.getDate()} ${shortMonths[date.getMonth()]}`;
      }
      if (seconds >= secondsPer) {
          const amount = Math.floor(seconds / secondsPer);
          return `${amount} ${name}`;
      }
  }
  return 'Just now';
}

const getDate = (
  date: string | null,
  isDayRequired = true,
  isYearRequired = true
) => {
  if (date == null) return date
  const d = new Date(date)
  return `${isDayRequired ? days[d.getDay()] + ',' : ''} ${d.getDate()} ${
    months[d.getMonth()]
  }${isYearRequired ? ', ' + d.getFullYear() : ''}`
}

export default getDate
