import { Carousel } from '@dehaat/dds/web';
import Image from 'next/image';
import { useState } from 'react';
import { Attachments } from '@/models/Posts';
import { convertSlugToString, isArray } from '@/utils/helper';
import YouTubeEmbedVideo from '../YoutubeEmbedVideo';
interface Props {
  attachments: Attachments;
  slug?: string;
}
const MediaCardV2 = ({
  attachments,
  slug
}: Props) => {
  const [priorityContentloaded, setPrioirtyContentLoaded] = useState(false);
  const getMedia = () => {
    const {
      images,
      video_links,
      videos,
      audios
    } = attachments;
    if (isArray(videos) && videos.length > 0) {
      return <div className="w-full my-4 h-full">
          <Carousel carouselContainerStyles={{
          marginTop: 0,
          height: '100%'
        }} dotContainerStyles={{
          paddingLeft: '24px',
          paddingRight: '24px',
          marginLeft: '16px',
          marginRight: '16px',
          bottom: '6px',
          left: 0,
          right: 0,
          zIndex: 1
        }} pages={videos.length} autoplay={false} dotsStyles={{
          width: '6px',
          height: '6px',
          padding: 0,
          zIndex: 1,
          backgroundColor: 'gray',
          marginLeft: '4px',
          borderRadius: '30px'
        }} activeDotStyles={{
          backgroundColor: 'orange',
          padding: 0
        }}>
            {videos.map((video, index) => <YouTubeEmbedVideo key={index} videoSrc={video} title={slug ? convertSlugToString(slug) : ''} containerClassname="mx-0 w-webkit-fill" />)}
          </Carousel>
        </div>;
    }
    if (isArray(video_links) && video_links.length > 0) {
      return <YouTubeEmbedVideo videoSrc={video_links[0]} title={slug ? convertSlugToString(slug) : ''} containerClassname="mx-0" />;
    }
    if (isArray(images)) {
      return images.length > 1 ? <div className="relative my-4 w-full h-full">
          <Carousel carouselContainerStyles={{
          marginTop: 0
        }} dotContainerStyles={{
          paddingLeft: '24px',
          paddingRight: '24px',
          marginLeft: '16px',
          marginRight: '16px',
          bottom: '6px',
          left: 0,
          right: 0,
          zIndex: 1
        }} pages={images.length} autoplay={priorityContentloaded} dotsStyles={{
          width: '6px',
          height: '6px',
          padding: 0,
          zIndex: 1,
          backgroundColor: 'gray',
          marginLeft: '4px',
          borderRadius: '30px'
        }} activeDotStyles={{
          backgroundColor: 'orange',
          padding: 0
        }}>
            {images.map((image, index) => <div className="relative w-full aspect-[1.6] min-h-[200px]" key={index}>
                <Image src={image} alt={slug ? convertSlugToString(slug) : ''} className="rounded-lg" fill priority={index === 0} onLoadingComplete={() => setPrioirtyContentLoaded(true)} />
              </div>)}
          </Carousel>
        </div> : <div className="relative w-full mt-4 aspect-[1.6]">
          <Image src={images[0]} alt={slug ? convertSlugToString(slug) : ''} className="rounded-lg" fill priority />
        </div>;
    }
    //TODO:: How to play audios
    if (isArray(audios) && audios.length > 0) {
      return <div className="w-full" data-testid={`audio-player-${slug}`}>
          <audio controls src={audios[0]} className="w-full" />
        </div>;
    }
  };
  return <div className="w-full h-full max-w-4xl" data-sentry-component="MediaCardV2" data-sentry-source-file="MediaCardV2.tsx">{getMedia()}</div>;
};
export default MediaCardV2;