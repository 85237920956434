import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { REDIRECT_URI_KEY } from '@/constants/common';
import Chacha from '@/images/login-chacha.webp';
import SubHeading, { VARIANT as SUBHEADING_VARAINT } from './SubHeading';
const LoginDrawer = () => {
  const {
    t
  } = useTranslation('common');
  const {
    asPath
  } = useRouter();
  return <div className="bg-white w-full p-4" data-sentry-component="LoginDrawer" data-sentry-source-file="LoginDrawer.tsx">
      <SubHeading variant={SUBHEADING_VARAINT.BIG} className="pb-4 border-b border-neutral-30 mb-4" data-sentry-element="SubHeading" data-sentry-source-file="LoginDrawer.tsx">
        {t('please_login_caption')}
      </SubHeading>
      <div className=" flex flex-col items-center justify-center">
        <div className="h-28 w-[120px] relative">
          <Image src={Chacha} placeholder="blur" layout="fill" objectFit="contain" aria-hidden="true" role="presentation" alt="please login" data-sentry-element="Image" data-sentry-source-file="LoginDrawer.tsx" />
        </div>

        <Link className="p-4 w-full bg-primary-100 mt-4 rounded-lg text-white text-center capitalize font-nato-semibold" href={`/login?${REDIRECT_URI_KEY}=${asPath}`} data-sentry-element="Link" data-sentry-source-file="LoginDrawer.tsx">
          {t('login_button')}
        </Link>
      </div>
    </div>;
};
export default LoginDrawer;