export const enum POST_VIEW_TYPE {
  SIMPLE_POSTS = 'simple_post_1x',
  MULTI_VIEW_CAROUSEL = 'multi_view_carousel',
  USER_SCROLL_V1 = 'user_scroll_v1',
  PRODUCT_PROMOTION = 'product_promotion_v1',
  POLLS_POST = 'simple_post_poll',
  MULTI_VIEW_CAROUSEL_V2 = 'multi_view_carousel_v2',
}

export const enum POST_TYPE {
  Community = 'COMMUNITY_POST',
  Blog = 'BLOG_POST',
}

export const popUpBannerDelay = 40 * 1000
