import { forwardRef, Ref, SVGAttributes } from 'react';
type Props = SVGAttributes<SVGSVGElement> & {
  tickClassName?: string;
};
const UserVerifiedIcon = forwardRef(({
  tickClassName = 'fill-white',
  ...props
}: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path d="M6.08 5 4 8l3.46 5L13 5l-2.08-2-3.46 5-1.38-3Z" className={tickClassName} />
      <path d="M15.41 6.93c-.33-.3-.45-.8-.28-1.2l.16-.39c.25-.59-.08-1.26-.69-1.44l-.4-.1a1.07 1.07 0 0 1-.76-.97l-.02-.42c-.03-.56-.5-1-1.04-1-.05 0-.12 0-.17.03l-.38.05-.18.03a1 1 0 0 1-.91-.57l-.2-.36c-.2-.36-.56-.56-.91-.56-.2 0-.43.05-.6.2l-.34.23c-.17.13-.4.2-.6.2-.23 0-.43-.07-.6-.2L7.14.21C6.98.08 6.75 0 6.55 0c-.38 0-.73.2-.91.54l-.2.36c-.18.33-.53.54-.91.54-.05 0-.13 0-.18-.03l-.4-.07-.18-.03c-.56 0-1.01.44-1.04 1l-.02.41c-.03.46-.33.85-.76.98l-.48.18c-.6.18-.93.85-.68 1.44l.15.38c.18.41.05.9-.28 1.2l-.3.3a1.06 1.06 0 0 0 0 1.58l.3.29c.33.3.46.8.28 1.2l-.18.39c-.25.59.08 1.26.69 1.44l.4.1c.43.13.73.51.76.98l.02.4c.03.57.5 1 1.04 1 .05 0 .12 0 .18-.02l.4-.05.18-.03a1 1 0 0 1 .9.57l.2.36c.21.36.56.56.92.56.2 0 .43-.05.6-.2l.33-.23c.18-.13.4-.2.6-.2.23 0 .44.07.61.2l.33.25c.18.13.4.21.6.21.39 0 .74-.2.92-.54l.2-.36c.18-.33.53-.54.9-.54.06 0 .13 0 .19.03l.4.07.18.03c.55 0 1-.44 1.03-1l.03-.41c.02-.46.33-.85.75-.98l.4-.1c.62-.18.94-.85.7-1.44l-.16-.38c-.18-.42-.05-.9.28-1.21l.3-.28c.48-.41.48-1.19 0-1.6l-.23-.36ZM8.47 9.48 7.2 10.76 5.94 9.48 4.73 8.22l1.26-1.29L7.2 8.2l2.9-2.95 1.27 1.28-2.9 2.96Z" />
    </svg>);
export default UserVerifiedIcon;