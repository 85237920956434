import LazyImage from '@/components/LazyImage';
import SimpleUserIcon from '../icons/SimpleUserIcon';
import UserVerifiedIcon from '../icons/UserVerifiedIcon';
interface Props {
  imageUrl?: string | null;
  alt: string;
  isVerified?: boolean;
  className?: string;
  fallbackImageClassName?: string;
}
const UserVerifiedImage = ({
  imageUrl,
  alt,
  isVerified,
  className,
  fallbackImageClassName
}: Props) => {
  return <div className={`relative flex justify-center h-11 w-11 ${className}`} data-sentry-component="UserVerifiedImage" data-sentry-source-file="UserVerifiedImage.tsx">
      <LazyImage imageUrl={imageUrl} alt={alt} fallBackIcon={<div className={`w-11 h-11 rounded-full bg-neutral-10 flex items-center justify-center ${fallbackImageClassName}`}>
            <SimpleUserIcon className="h-5 w-5 fill-neutral-40" />
          </div>} className="h-11 w-11 rounded-full" data-sentry-element="LazyImage" data-sentry-source-file="UserVerifiedImage.tsx" />
      {isVerified && <UserVerifiedIcon className="fill-primary-100 h-5 w-5 absolute bottom-0 -right-1" />}
    </div>;
};
export default UserVerifiedImage;