import { useState } from 'react';
import { getThumbnailUrl } from '@/utils/helper';
interface Props {
  videoSrc: string;
  title: string;
  onClick?: VoidFunction; //Can be used to trigger events for video playing
  containerClassname?: string;
  height?: string;
}
const YouTubeEmbedVideo = ({
  videoSrc,
  title,
  onClick,
  containerClassname = 'mx-4',
  height = 'h-48'
}: Props) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const handleThumbnailClick = () => {
    if (onClick) onClick();
    setIsVideoLoaded(true);
  };
  return <div className={`${containerClassname} w-webkit-fill`} data-sentry-component="YouTubeEmbedVideo" data-sentry-source-file="YoutubeEmbedVideo.tsx">
      {isVideoLoaded ? <iframe title={title} src={`${videoSrc}?autoplay=1`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className={`h-48 rounded-xl border w-webkit-fill ${height}`} /> : <div className={`flex items-center justify-center h-48 mt-4 rounded-xl border bg-cover bg-center cursor-pointer ${height}`}
    // Thumbnail image when video script is not loaded
    style={{
      backgroundImage: `url(${getThumbnailUrl(videoSrc)})`
    }} onClick={handleThumbnailClick}>
          <div className="flex justify-center">
            {/* Play button svg to be shown on thumbnail when video is not loaded */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
              <path fill="#FF3D00" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path>
              <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
            </svg>
          </div>
        </div>}
    </div>;
};
export default YouTubeEmbedVideo;